import "./styles.css";
import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import SideNavLayout from "./layouts/SideNavLayout";
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

const darkTheme = createTheme({
    palette: {
        mode: "dark",
        type: "dark",
        primary: {
            main: "#3f51b5"
        },
        secondary: {
            main: "#f50057"
        },
        background: {
            default: "#001e3c",
            paper: "#002e54"
        }
    }
});

function App({ signOut, user }) { 
    return (
        <ThemeProvider theme={darkTheme}>
            <SideNavLayout title={"Chris' Calculators & Converters"} />
        </ThemeProvider>
    );
}

export default withAuthenticator(App);
