import * as React from "react";
import { NavLink } from "react-router-dom";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
//import InboxIcon from "@mui/icons-material/MoveToInbox";
//import MailIcon from "@mui/icons-material/Mail";
import DeblurIcon from "@mui/icons-material/Deblur";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import SchemaIcon from "@mui/icons-material/Schema";
import TransformIcon from "@mui/icons-material/Transform";
import SummarizeIcon from "@mui/icons-material/Summarize";
// For flipping:
// transform: [{ scaleX: '-1' }]

const t_mainMenuItems = [
    {
        icon: <DeblurIcon />,
        route: "/timesheet_converters",
        text: "Timesheet Converters"
    },
    {
        icon: <FileCopyIcon />,
        route: "/form_converters",
        text: "Form Converters"
    },
    {
        icon: <SchemaIcon />,
        route: "/file_converters",
        text: "File Converters"
    },
    {
        icon: <TransformIcon />,
        route: "/qb_converters",
        text: "QB Export Converters"
    },
    {
        icon: <SchemaIcon />,
        route: "/ms_calendar_extractor",
        text: "MS Calendar Extractor"
    },
    {
        icon: <SummarizeIcon />,
        route: "/pdf_converters",
        text: "PDF Converters"
    },
    {
        icon: <DeblurIcon />,
        route: "/ocr",
        text: "OCR PDFs"
    },
    {
        icon: <FileCopyIcon />,
        route: "/ai",
        text: "AI Text Generation"
    }
];

function SideNav() {
    return (
        <div>
            <Toolbar />
            <Divider />
            <List>
                <nav>
                    {t_mainMenuItems.map((row) => (
                        <NavLink to={row.route} key={row.route}>
                            <ListItem button key={row.text}>
                                <ListItemIcon>{row.icon}</ListItemIcon>
                                <ListItemText primary={row.text}></ListItemText>
                            </ListItem>
                        </NavLink>
                    ))}
                </nav>
            </List>
        </div>
    );
}

export default SideNav;
