import React, { lazy } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from "./App";
import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
Amplify.configure(awsExports);

const WorkshareConverter = lazy(() => import("./pages/WorkshareConverter"));
const MsCalendarExtractor = lazy(() => import("./pages/MsCalendarExtractor"));
const PDFConverters = lazy(() => import("./pages/PDFConverters"));
const TimesheetConverters = lazy(() => import("./pages/TimesheetConverters"));
const FormConverters = lazy(() => import("./pages/FormConverters"));
const FileConverters = lazy(() => import("./pages/FileConverters"));
const QBConverters = lazy(() => import("./pages/QBConverters"));
const OCR = lazy(() => import("./pages/OCR"));
const AIText = lazy(() => import("./pages/AIText"));

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);
root.render(
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<App />}>
                <Route path="workshare_converter" element={<WorkshareConverter />} />
                <Route path="ms_calendar_extractor" element={<MsCalendarExtractor />} />
                <Route path="pdf_converters" element={<PDFConverters />} />
                <Route path="timesheet_converters" element={<TimesheetConverters />} />
                <Route path="form_converters" element={<FormConverters />} />
                <Route path="file_converters" element={<FileConverters />} />
                <Route path="qb_converters" element={<QBConverters />} />
                <Route path="ocr" element={<OCR />} />
                <Route path="ai" element={<AIText />} />
            </Route>
        </Routes>
    </BrowserRouter>
);
