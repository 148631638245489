/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ca-central-1",
    "aws_appsync_graphqlEndpoint": "https://avfw6gwp6ramhhmcxluwkgzouq.appsync-api.ca-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "ca-central-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-pq3nigamxzasxh7ky4xdb3qrce",
    "aws_cognito_identity_pool_id": "ca-central-1:cd72aecd-4a5e-415f-b298-fb1cd2467365",
    "aws_cognito_region": "ca-central-1",
    "aws_user_pools_id": "ca-central-1_APcwJZ6lL",
    "aws_user_pools_web_client_id": "475n5j4hjos9iodub7vnvdljh7",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
