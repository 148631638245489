import React, { useEffect, useState, Suspense } from 'react';
import { Auth } from 'aws-amplify'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import SideNav from 'layouts/SideNav'
import { Outlet } from 'react-router-dom'
import QrCode2Icon from '@mui/icons-material/QrCode2'
//import StartIcon from "@mui/icons-material/Start";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'

const drawerWidth = 240

function SideNavLayout(props) {
    const { title, subMenuItems } = props;
    const [ user, setUser ] = useState();
    useEffect(() => {
        async function fetchUser() {
            const t_user = await Auth.currentAuthenticatedUser();
            //console.log(t_user);
            setUser(t_user);
        }
        fetchUser();
    },[]);
    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar>
                <Toolbar>
                    <Typography sx={{ width: 220 }}>
                        <QrCode2Icon fontSize="large" />
                        <ArrowForwardIcon fontSize="large" />
                        <InsertDriveFileIcon fontSize="large" />
                    </Typography>
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{ flexGrow: 1 }}
                    >
                        {title}
                    </Typography>
                    <Typography sx={{ m: 1 }}>
                            {user?.attributes?.email}
                    </Typography>
                    <Typography>
                        <Button
                            variant="contained"
                            onClick={() => Auth.signOut()}
                        >
                            Sign Out
                        </Button>
                    </Typography>
                </Toolbar>
            </AppBar>
            <Box component="nav" sx={{ width: { sm: drawerWidth } }}>
                <SideNav subMenuItems={subMenuItems} />
            </Box>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    div: 3,
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                }}
            >
                <Toolbar />
                <Suspense fallback={<div>Loading...</div>}>
                    <Outlet />
                </Suspense>
            </Box>
        </Box>
    )
}

export default SideNavLayout;
